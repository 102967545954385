window.outer = '';

(() => {
  const AdminNetworking = {
    changeRole: function changeRole(userId, role) {
      const url = `/users/${userId}`;
      const authToken = $('meta[name=csrf-token]').attr('content');
      $.ajax(url, {
        method: 'POST',
        data: {
          role,
          authenticity_token: authToken
        },
        success: () => {
          window.location.reload();
        }
      });
    },

    deleteUser: function deleteUser(userId) {
      const url = `/users/${userId}`;
      const authToken = $('meta[name=csrf-token]').attr('content');
      $.ajax(url, {
        method: 'DELETE',
        data: { authenticity_token: authToken },
        success: () => {
          window.location.reload();
        }
      });
    }
  };

  class UserManagement {
    constructor($root) {
      this.$root = $root;
      this.$userList = $root.find('.userList');

      this.setup();
    }

    setup() {
      this.$deleteButtons = this.$userList.find('.deleteUser div');
      this.$deleteButtons.unbind('click');
      this.$deleteButtons.click((event) => {
        const $clicked = $(event.currentTarget);
        window.outer = $clicked;
        const userId = $clicked.parents('tr.user').first().data('userId');
        const name = $clicked.parents('tr.user').first().data('userName');
        const confirm = window.confirm(`Click OK to delete ${name}`);
        if (confirm) {
          AdminNetworking.deleteUser(userId);
        }
      });

      this.$checkboxes = this.$userList.find('input[type="checkbox"]');
      this.$checkboxes.unbind('click');
      this.$checkboxes.click((event) => {
        const $clicked = $(event.currentTarget);
        const userId = $clicked.parents('tr.user').data('userId');
        const role = event.currentTarget.checked ? 'admin' : 'user';

        AdminNetworking.changeRole(userId, role);
      });
    }
  }
  window.UserManagement = UserManagement;
})();

document.addEventListener('turbolinks:load', () => {
  const isAdmin = $('body.isAdmin').length > 0;

  if (!isAdmin) { return; }

  // eslint-disable-next-line no-new
  new UserManagement($('body.isAdmin .pageWrapper'));
});
