(function () {
  window.isUnset = function isUnset(x) {
    return x === undefined || x === null;
  };

  console.info("Loading it.")

  /**
   * @param {Object} x - Test Object
   * @returns {boolean} Returns true if the value is not undefined or null
   */
  window.isSet = function isSet(x) {
    return !isUnset(x);
  };

  /**
   * @param {Object} x - Test Object
   * @returns {boolean} Returns true if the thing is unset, is an
   * empty object or responds to .length
   * which returns 0.
   */
  window.isUnsetOrEmpty = function isUnsetOrEmpty(x) {
    if (isUnset(x)) { return true; }

    if (isUnset(x.length)) {
      return false; // only testing 'length' for emptiness, otherwise assume it isn't.
    }

    return x.length === 0;
  };

  /**
   * @returns {boolean} Returns true if the parameter is set and typeof(x) === 'function'
   */
  window.isFunction = function (x) {
    return isSet(x) && typeof (x) === 'function';
  };
}());
