(function () {
  window.SimpleThing = function (bob) {
    const name = bob;

    this.sayName = function () {
      console.info(`Your name is ${name}`);
      return name;
    };
  };
}());
