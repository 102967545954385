window.Toggler = function ($rootElement) {
  const debug = false;
  const $root = $rootElement;
  const $checkbox = $root.find('.toggleCheckbox');
  const $hidden = $root.find('.visibleState');
  const $showIfChecked = $root.find('.showIfChecked');
  const platform = $root.data('platform');

  const self = this;
  this.clickHandler = null;

  this.setVisibility = function (isChecked) {
    const checkboxChecked = $checkbox.is(':checked');
    if (isChecked && !checkboxChecked) {
      $checkbox.attr('checked', 'checked');
    } else if (!isChecked && checkboxChecked) {
      $checkbox.attr('checked', '');
    }

    if (isChecked) {
      $showIfChecked.fadeIn();
    } else {
      $showIfChecked.fadeOut();
    }
  };

  $checkbox.click(() => {
    const isChecked = $checkbox.is(':checked');

    if (debug) console.info(`Toggler::clickHandler::Checkbox toggled at ${platform} toggled to ${isChecked}`);
    $hidden.val(isChecked);

    if (isFunction(self.clickHandler)) {
      self.clickHandler(platform, isChecked);
    }
    self.setVisibility(isChecked);
  });

  // Initialize it
  self.setVisibility($hidden.val() === 'true');
};
