window.SelectorThing = (function () {
  return function (rootSelector) {
    const root = $(rootSelector);
    const outSelect = $(root.find('.out'));
    const inSelect = $(root.find('.in'));
    const moveOut = root.find('.moveOut');
    const moveIn = root.find('.moveIn');

    function moveInClickHandler() {
      outSelect.find('option:selected').remove().appendTo(inSelect);
      inSelect.find('option').removeAttr('selected');
    }

    function moveOutClickHandler() {
      inSelect.find('option:selected').remove().appendTo(outSelect);
      outSelect.find('option').removeAttr('selected');
    }

    moveIn.click(moveInClickHandler);
    moveOut.click(moveOutClickHandler);

    this.getCodes = function (select) {
      const codes = [];
      select.find('option').each(function () {
        const option = $(this);
        const code = option.val();
        codes.push(code);
      });

      return codes.join(',');
    };

    this.inCodes = function () {
      return this.getCodes(inSelect);
    };

    this.outCodes = function () {
      return this.getCodes(outSelect);
    };

    this.moveIn = function (list) {
      if (isUnsetOrEmpty(list)) { return; }

      _.each(list, (itemWithCode) => {
        if (isUnset(itemWithCode)) { return; }
        outSelect.find('option').each(function () {
          const option = $(this);
          const optionCode = option.val();
          if (optionCode === itemWithCode.code) {
            option.attr('selected', true);
          }
        });
        moveInClickHandler();
      });
    };
  };
}());
